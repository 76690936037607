<template>
  <div class="enterprise-certifed">
    <div class="ec-step">
      <div class="ecs-circle">
        <div class="ecs-circle-round display-flex-center">
          1
        </div>
      </div>
      <div class="ecs-circle-line ecs-grey-line"/>
      <div :class="['ecs-circle-line',nowIndex===0 ? 'ecs-grey-line' : '',]"/>
      <div :class="['ecs-circle',nowIndex===0 ? 'ecs-grey-line' : '',]">
        <div :class="['ecs-circle-round','display-flex-center',nowIndex===0 ? 'ecs-grey-bg' : '',]">
          2
        </div>
      </div>
    </div>
    <div class="ecs-text">
      <span :class="{'ecs-grey-text':nowIndex===1}">企业基本信息</span>
      <span :class="{'ecs-grey-text':nowIndex===0}">企业产品信息</span>
    </div>

    <div class="ec-body" v-if="nowIndex===0">
      <div class="ecb-one">
        <Form :model="enterpriseForm" :label-width="150" :rules="enterpriseFormRule">
          <FormItem label="企业名称" prop="name">
            <Input v-model="enterpriseForm.name" placeholder="请输入企业名称"/>
          </FormItem>
          <FormItem label="企业统一社会码" prop="creditCode">
            <Input v-model="enterpriseForm.creditCode" placeholder="请输入企业名称"/>
          </FormItem>
          <FormItem label="营业执照" prop="file">
            <Upload
                :format="['jpg','png']"
                :max-size="1024"
                type="drag"
                :before-upload="(file) => handleUpload(file)"
                :show-upload-list="showUploadList"
                action="">
              <img v-if="!enterpriseLicenseUrl"
                   src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_upload_bg.png"
                   class="upload-img"/>
              <img v-if="enterpriseLicenseUrl" :src="enterpriseLicenseUrl" class="upload-img"/>
            </Upload>
          </FormItem>
        </Form>
        <div class="ecb-one-next display-flex-center" @click="nextStep">下一步</div>
      </div>
    </div>

    <div class="ec-body" v-if="nowIndex===1">
      <div class="ec-two">
        <div class="ec-two-list" style="width: 570px">
          <div class="ec-two-list-title">企业信息</div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">法定代表人</div>
            <input class="ec-two-input-in" v-model="productForm.legalRepresentative"/>
            <Button class="ec-two-input-button" size="small" @click="correct('法定代表人','legalRepresentative')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">企业地址</div>
            <input class="ec-two-input-in" v-model="productForm.site"/>
            <Button class="ec-two-input-button" size="small" @click="correct('企业地址','site')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">行政区划</div>
            <input class="ec-two-input-in" v-model="productForm.administrativeDivision"/>
            <Button class="ec-two-input-button" size="small" @click="correct('行政区划','administrativeDivision')">纠错
            </Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">所属科技领域</div>
            <input class="ec-two-input-in" v-model="productForm.satTerritory"/>
            <Button class="ec-two-input-button" size="small" @click="correct('所属科技领域','satTerritory')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">注册资本金</div>
            <input class="ec-two-input-in" v-model="productForm.registeredCapital"/>
            <Button class="ec-two-input-button" size="small" @click="correct('注册资本金','registeredCapital')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">注册时间</div>
            <input class="ec-two-input-in" v-model="productForm.registerTime"/>
            <Button class="ec-two-input-button" size="small" @click="correct('注册时间','registerTime')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">所属行业</div>
            <input class="ec-two-input-in" v-model="productForm.involved"/>
            <Button class="ec-two-input-button" size="small" @click="correct('所属行业','involved')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">员工数量</div>
            <input class="ec-two-input-in" v-model="productForm.staffTotal"/>
            <Button class="ec-two-input-button" size="small" @click="correct('员工数量','staffTotal')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">企业类型</div>
            <input class="ec-two-input-in" v-model="productForm.enterpriseType"/>
            <Button class="ec-two-input-button" size="small" @click="correct('企业类型','enterpriseType')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">营业期限</div>
            <input class="ec-two-input-in" v-model="productForm.businessTerm"/>
            <Button class="ec-two-input-button" size="small" @click="correct('营业期限','businessTerm')">纠错</Button>
          </div>
          <div class="ec-two-input">
            <div class="ec-two-input-text">经营范围</div>
            <input class="ec-two-input-in" v-model="productForm.businessScope"/>
            <Button class="ec-two-input-button" size="small" @click="correct('经营范围','businessScope')">纠错</Button>
          </div>
        </div>
        <div class="ec-two-list">
          <div class="ec-two-list-title">产品信息</div>
          <Form :model="productForm" :label-width="150" :rules="requireRule" style="margin-top: 20px">
            <FormItem label="主要产品名称" prop="productName">
              <Input v-model="productForm.productName" placeholder='主要产品名称（多个以";"分隔）' style="width: 370px"></Input>
            </FormItem>
            <FormItem label="主要产品领域">
              <Input v-model="productForm.mptName" placeholder='主要产品领域（多个以";"分隔）' style="width: 370px"></Input>
            </FormItem>
            <FormItem label="主要产品标签" prop="productLabel">
              <Input v-model="productForm.productLabel" placeholder='主要产品标签（多个以";"分隔）' style="width: 370px"></Input>
            </FormItem>
            <FormItem label="主要产品描述">
              <Input v-model="productForm.productDescribe" placeholder='主要产品描述' style="width: 370px" type="textarea"
                     :autosize="{minRows: 5,maxRows: 8}"></Input>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="ec-two-button display-flex-center" @click="goRenZheng">提交认证</div>
    </div>
  </div>
</template>

<script>
import {
  successToast,
  warnToast,
  errorToast
} from "@/plugins/tools/util";
import {uploadImg, getBasicDetail, saveInfo} from "@/plugins/api/EnterpriseCertification";
import {mapMutations, mapGetters} from 'vuex'
import {Message, Spin} from "view-design";

export default {
  name: "EnterpriseCertification",
  mounted() {
    if (this.enterpriseState === 'EXAMINING' || this.enterpriseState === 'CERTIFIED') {
      this.$router.push({
        path: '/'
      })
    }
  },
  data() {
    return {
      showUploadList: false,
      enterpriseForm: {
        name: "",
        creditCode: "",
        file: "",
      },
      enterpriseFormRule: {
        name: [{required: true, message: '企业名称必填',}],
        creditCode: [{required: true, message: '企业统一社会码必填',}],
        file: [{required: true, message: '请上传营业执照',}],
      },
      nowIndex: 0,
      enterpriseLicenseUrl: "",
      productForm: {
        legalRepresentative: '',
        involved: '',
        site: '',
        enterpriseLicenseUrl: '',
        staffTotal: '',
        administrativeDivision: '',
        enterpriseType: '',
        satTerritory: '',
        businessTerm: '',
        registeredCapital: '',
        businessScope: '',
        registerTime: '',
        productName: '',
        mptName: '',
        productLabel: '',
        productDescribe: ''
      },
      requireRule: {
        productName: [
          {
            required: true,
            message: '产品名称必填',
          }
        ],
        productLabel: [
          {required: true, message: '标签名必填', trigger: 'blur'}
        ],
      }

    }
  },

  computed: {
    ...mapGetters({'enterpriseState': 'userStore/enterpriseState'})
  },
  methods: {
    ...mapMutations({
      setUserToken: 'userStore/setUserToken',
      setUserName: 'userStore/setUserName',
      setUserAccountType: 'userStore/setUserAccountType',
      setUserMbrMemberId: 'userStore/setUserMbrMemberId',
      setUserId: 'userStore/setUserId',
      setUserEnterpriseState: 'userStore/setUserEnterpriseState'
    }),
    correct(cName, eName) {
      this.productForm[eName] = "";
    },
    async nextStep() {
      let data = {
        name: this.enterpriseForm.creditCode,
      };
      if (!this.enterpriseForm.creditCode) {
        warnToast('请输入企业统一社会码')
        return
      }
      if (!this.enterpriseForm.name) {
        warnToast('请输入企业名称')
        return
      }
      if (!this.enterpriseLicenseUrl) {
        warnToast('请上传营业执照')
        return
      }
      let json = await getBasicDetail(data);
      if (json.code == 0) {
        for (let key in json.result) {
          this.productForm[key] = json.result[key]
        }
        this.nowIndex = 1;
      } else {
        errorToast(json.message)
      }
    },
    async handleUpload(file) {
      this.file = file.name;
      const FileExt = this.file.replace(/.+\./, "");//取得文件的后缀名
      if (FileExt === 'png' || FileExt === 'jpg') {
        const reader = new FileReader();
        //将文件读取为 DataURL 以data:开头的字符串
        let code = '';
        let _that = this;
        reader.readAsDataURL(file);
        reader.onload = e => {
          // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
          code = e.target.result;

          let data = {
            imageBase64: code,
            dataType: 0
          };
          uploadImg(data).then(res => {
            if (res.code === 200) {
              _that.enterpriseLicenseUrl = res.data
              Spin.hide()
              successToast("上传成功!")
            } else {
              warnToast(res.message)
            }
          })
        }

      } else {
        warnToast('只支持jpg和png')
      }

    },
    async goRenZheng() {
      this.productForm.enterpriseName = this.enterpriseForm.name;
      this.productForm.creditCode = this.enterpriseForm.creditCode;
      this.productForm.enterpriseLicenseUrl = this.enterpriseLicenseUrl;
      if (this.productForm.productLabel == "") {
        warnToast("请将产品标签填写完成")
        return;
      }
      if (this.productForm.productName == "") {
        warnToast("请将产品名称填写完成")
        return;
      }
      let json = await saveInfo(this.productForm)
      if (json.code == 0) {
        successToast("等待审核")
        this.setUserEnterpriseState('EXAMINING')
        this.$router.push({
          path: '/enterprise/enterprise-management-center',
          query: {
            id: '0'
          }
        })
        scrollTo(0, 0)
      } else {
        errorToast(json.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .ivu-upload-drag {
  width: 290px;
  height: 185px;
}

.enterprise-certifed {
  background-color: rgba(244, 248, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ec-step {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  align-items: center;
}

.ecs-circle {
  width: 40px;
  height: 40px;
  border: 2px solid #0F62DF;
  border-radius: 50%;
  padding: 2px;
}

.ecs-circle-round {
  width: 100%;
  height: 100%;
  background-color: rgba(15, 98, 223, 1);
  border-radius: 50px;
  font-size: 24px;
  font-family: Microsoft YaHei, serif;
  font-weight: 400;
  color: #F6FAFF;
}

.ecs-circle-line {
  width: 98px;
  height: 1px;
  border-bottom: 1px dashed rgba(15, 98, 223, 1);
}

.ecs-grey-bg {
  background-color: rgba(194, 194, 194, 1);
}

.ecs-grey-line {
  border-color: rgba(194, 194, 194, 1);
}

.ecs-grey-text {
  color: rgba(164, 164, 164, 1);
}

.ecs-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  margin-top: 10px;
  width: 320px;
}

.ec-body {
  width: 1200px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin-top: 30px;
  margin-bottom: 51px;
  background-color: rgba(255, 255, 255, 1);
}

.ecb-one {
  width: 630px;
  margin: 48px auto;
}

.upload-img {
  width: 290px;
  height: 185px;
}

.ecb-one-next {
  width: 200px;
  height: 40px;
  background: #0F62DF;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  margin: 168px auto 0 auto;
  cursor: pointer;
}

.ec-two {
  display: flex;
  flex-direction: row;
  margin: 30px;
  justify-content: space-between;
}

.ec-two-list {
  display: flex;
  flex-direction: column;
}


.ec-two-list-title {
  width: 108px;
  height: 28px;
  background: linear-gradient(90deg, #0F62DF 0%, #FFFFFF 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 24px;
}

.ec-two-input {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
}

.ec-two-input-text {
  width: 94px;
  display: flex;
  flex-direction: row-reverse;
}

.ec-two-input-in {
  width: 370px;
  height: 40px;
  border: 1px solid #D0D0D0;
  outline: none;
  padding-left: 24px;
  margin-left: 19px;
}

.ec-two-input-button {
  background: #FFDBCD;
  border-radius: 4px;
  color: #FE7930;
  margin-left: 10px;
}

.ec-two-button {
  width: 200px;
  height: 40px;
  background: #0F62DF;
  margin: 0 auto 41px auto;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;

}
</style>
